import styles from "./Home.module.scss";
import { Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import HeadCaption from "../../components/home/HeadCaption";
import Search from "../../components/home/Search";
import Features from "../../components/home/Features";
import Top10Panel from "components/home/Top10Panel";
import SectionTitle from "components/SectionTitle";

function Home() {
  return (
    <div className={`${styles.Home}`}>
      <Container className="sm:px-0">
        <section className="flex justify-center xs:pt-8 md:pt-16 pb-12">
          <HeadCaption />
        </section>
        <section className="xs:mb-14 md:mb-20">
          <Search className="search mx-auto" />
        </section>
        <section className="xs:mb-4 md:mb-4">
          <Features />
        </section>
        <section className="sm:px-0 hidden">
          <h3 className="text-2xl font-extrabold leading-10 page-title">  <span className=" nc-text-grad"> Articles </span> </h3>
        </section>
        <section className="xs:mb-4 md:mb-4 hidden">
          <Top10Panel
            title="Navigating the Web: Top 10 Domain Providers and Beyond"
            link="/top-10-domain-providers"
          >
            Explore the diverse landscape of domain registration with our comprehensive guide highlighting the top 10 domain providers, each offering unique features and services for establishing a robust online presence.
          </Top10Panel>
        </section>
        <div className="flex flex-wrap hidden">
          <section className="xs:mb-4 md:mb-4 w-full md:w-1/2 pr-2">
            <Top10Panel
              title="The Crucial Role of Consistent Online Identity, Domains, and Usernames for Business Success"
              link="/consistent-online-identity"
            >
              Unlock business success with a consistent online identity. From domains to usernames, discover how unity builds trust, recognition, and adaptability.
            </Top10Panel>
          </section>
          <section className="xs:mb-4 md:mb-4 w-full md:w-1/2 pl-2">
            <Top10Panel
              title="The Advantages of Website Creators: A Spotlight on Squarespace and Wix
              "
              link="/the-advantages-of-website-creators"
            >
              Discover the power of website creators like Squarespace and Wix. From user-friendly design to robust e-commerce, create a stunning online presence effortlessly.
            </Top10Panel>
          </section>
        </div>
        <section className="xs:mb-4 md:mb-24 hidden">
          <Top10Panel
            title="Exploring the Best: A General Top 10 List of Website Builders"
            link="/top-10-list-of-website-builders"
          >
            Explore top website builders like Squarespace, Wix, and WordPress. Find the perfect fit for your online presence with insights on Shopify, WooCommerce, and more.
          </Top10Panel>
        </section>
      </Container>
    </div>
  );
}

export default Home;
