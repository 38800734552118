import PropTypes from "prop-types";
import styles from "./DomainAccordion.module.scss";
import { Accordion, AccordionContext, Card } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AvailabilityStatus from "components/AvailabilityStatus";
import ExtIconLink from "components/ExtIconLink";
import Badge from "components/Badge";
import { useContext } from "react";

const disabledRegistrarUrl = "https://www.godaddy.com/domainsearch/find?domainToCheck=";

export const DomainAccordionLoader = (props) => {
  const { className } = props;
  return (
    <div className={`${styles.DomainAccordionLoader} ${className}`}>
      <div className="flex items-center h-full justify-between">
        <div>
          <div className="title rounded-2xl shimmer"></div>
        </div>
        <div>
          <div className="status-label rounded-2xl shimmer"></div>
        </div>
      </div>
    </div>
  );
};

function ContextAwareToggle({ children, eventKey, callback, disabled, domainName }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const onDisabledClick = (e) => {
    e.stopPropagation();
    console.log("EventKey: ", eventKey)
    //open url in a new tab
    window.open(disabledRegistrarUrl + domainName, "_blank")
  };
  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      className={`accordion-button ${isCurrentEventKey ? "" : "collapsed"}`}
      type="button"
      onClick={disabled ? onDisabledClick : decoratedOnClick}
    >
      {children}
    </button>
  );
}

const DomainAccordion = (props) => {
  const { variant, className, domain, eventKey } = props;
  return (
    <Accordion.Item
      className={`${styles.DomainAccordion} ${styles[`DomainAccordion__${variant}`]
        } ${className}`}
      eventKey={eventKey}
      title={domain.available ? null : "You might still be able to get this domain. Click to see how."}
    >
      <Card.Header
        className={`head ${domain.available ? "available" : "unavailable"}`}
      >
        <ContextAwareToggle eventKey={eventKey} disabled={!domain.available} domainName={domain.domain}>
          <div className="flex w-full justify-between items-center">
            <div>
              <span className="text-base font-semibold">
                <span className="title mr-3 font-extrabold"> {domain.domain} </span>
                <span className="text-sm block md:inline"> <AvailabilityStatus value={domain.available} />  </span>
              </span>
            </div>
            <div className="btn-buy-now">
              Buy now
            </div>
          </div>
        </ContextAwareToggle>
      </Card.Header>
      <Accordion.Body className="body">
        <div className="accordion-row">
          <div className="accordion-col lg:border-r border-nc-gray-300">
            <h3 className="text-gray-900 text-base font-bold mb-3">
              Website builders
            </h3>
            <ul>
              {domain.websiteRegistrarLinks.map((link) => (
                <li key={link.link} className="mb-3 last:!mb-0">
                  <ExtIconLink className="mr-2" href={link.url}>
                    <span className="font-medium mr-2">{link.link}</span>
                  </ExtIconLink>
                  {link.hasBadge && (
                    <Badge variant="outline">Free with website plan</Badge>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="accordion-col">
            <h3 className="text-gray-900 text-base font-bold mb-3">
              Domain registrars
            </h3>
            <ul>
              {domain.domainRegistrarLinks.map((link) => (
                <li key={link.link} className="mb-3 last:!mb-0">
                  <ExtIconLink className="mr-2" href={link.url} >
                    <span className="font-medium mr-2">{link.link}</span>
                  </ExtIconLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

DomainAccordion.defaultProps = {
  variant: "default",
  className: "",
};

DomainAccordion.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default DomainAccordion;
