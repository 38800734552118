import { Toaster } from "react-hot-toast";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export function DefaultLayout(props) {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Toaster />
        {props.children}
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default DefaultLayout;
