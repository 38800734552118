import PropTypes from "prop-types";
import { ReactComponent as ExtLinkIcon } from "assets/icons/external-link-icon.svg";
import styles from "./ExtIconLink.module.scss";

const ExtIconLink = (props) => {
  const { Tag = "a", variant, className, href, children } = props;
  return (
    <Tag
      className={`${styles.ExtIconLink} ${
        styles[`ExtIconLink__${variant}`]
      } ${className}`}
      href={href}
      target="__blank"
      rel="noopener noreferrer"
    >
      {children}
      <ExtLinkIcon />
    </Tag>
  );
};

ExtIconLink.defaultProps = {
  variant: "default",
  className: "",
};

ExtIconLink.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default ExtIconLink;
