import PropTypes from 'prop-types';
import { Container } from "react-bootstrap";
import styles from './About.module.scss';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className={styles.About}>
      <Container className="sm:px-0 py-8">
        <div className="w-full sm:w-3/4">
          <h1 className="text-2xl font-extrabold leading-10 nc-text-grad page-title"> About Namecheck.co - Your Ultimate Domain and Username Companion </h1>
        </div>
        <div className="py-3">
          <p className="text-gray-500 text-base font-normal font-medium leading-6">Welcome to Namecheck.co, where your online identity journey begins. We understand the significance of finding the perfect domain and online presence username for your personal brand or business. Our mission is to simplify this process, making it seamless, intuitive, and, above all, exciting.</p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 font-extrabold leading-6 py-1 nc-text-grad page-title">Who We Are</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> At Namecheck.co, we are a passionate team of tech enthusiasts and branding experts. We believe that everyone deserves a unique and memorable online identity. Whether you're an aspiring entrepreneur, a creative professional, or an established business, we're here to help you navigate the digital landscape with ease. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 font-extrabold leading-6 nc-text-grad page-title">What We Offer</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> 1. Domain Search and Comparison: Discover the perfect domain for your endeavors with our powerful search and comparison tools. Namecheck.co aggregates information from various domain registrars, providing you with a comprehensive overview of availability. Say goodbye to the hassle of visiting multiple sites - Namecheck.co is your all-in-one solution. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> 2. Username Search: In the age of connectivity, securing your brand across online platforms is essential. Our username search feature lets you explore the availability of usernames across various platforms. Unify your online presence effortlessly with Namecheck.co. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 font-extrabold leading-6 nc-text-grad page-title">Why Choose Namecheck.co</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> Comprehensive Listings: We've partnered with leading domain registrars and online platforms to ensure that our listings are comprehensive and up-to-date. Spend less time searching and more time building your brand. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> User-Friendly Interface: Our platform is designed with you in mind. Whether you're a seasoned professional or a first-time user, our user-friendly interface makes the entire process straightforward and enjoyable. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> Data Security: We prioritize the security of your information. Rest assured that your searches and personal details are handled with the utmost confidentiality. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 font-extrabold leading-6 nc-text-grad page-title">Get Started Today</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> Your perfect domain and usernames are just a click away. Begin your journey with Namecheck.co and unlock the potential of your online identity. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> Start Your Search Now and let Namecheck.co be your guide in the digital realm. </p>
        </div>
        <Button
          className="text-base rounded-lg max-w-sm py-3 px-5"
          type="primary"
          as={Link} to="/"
          onClick={() => window.scrollTo(0, 0)}
        >
          Start your search
        </Button>
      </Container>
    </div>
  );
};

About.defaultProps = {
  className: '',
};

About.propTypes = {
  className: PropTypes.string,
};

export default About;