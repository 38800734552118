import PropTypes from "prop-types";
import styles from "./SearchBar.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const SearchBar = (props) => {
  const searchBarRef = useRef();

  const { Tag = "div", variant, className } = props;

  const [keyword, setKeyword] = useState("");

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    navigate(`/search?keyword=${keyword}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window !== undefined) {
        let windowHeight = window.scrollY;
        const isFixed = searchBarRef.current.classList.contains("search-fixed");
        console.log(windowHeight);
        if (windowHeight > 97 && !isFixed) {
          document.querySelector("main").classList.add("search-fixed-pad");
          searchBarRef.current.classList.add("search-fixed");
        } else if (windowHeight <= 97 && isFixed) {
          document.querySelector("main").classList.remove("search-fixed-pad");
          searchBarRef.current.classList.remove("search-fixed");
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const initialKeyword = searchParams.get("keyword");
    setKeyword(initialKeyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tag
      className={`${styles.SearchBar} ${
        styles[`SearchBar__${variant}`]
      } ${className} bg-white`}
      ref={searchBarRef}
    >
      <Container className="sm:px-0">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="py-8">
            <div className="flex w-full sm:gap-3 xs:border border-nc-gray-300 rounded-lg sm:border-none">
              <Form.Control
                className="text-base rounded-lg px-4 py-3 text-gray-500 sm:w-96 md:w-full xs:border-none sm:border-solid border-nc-gray-300 shadow-none"
                size="lg"
                type="text"
                placeholder="Type here..."
                value={keyword}
                onChange={handleChange}
                required
              />
              <Button
                className="text-base rounded-lg py-2 !px-5 w-28"
                type="primary"
                size="lg"
              >
                Search
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </Tag>
  );
};

SearchBar.defaultProps = {
  variant: "default",
  className: "",
};

SearchBar.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default SearchBar;
