import PropTypes from 'prop-types';
import styles from './Top10Panel.module.scss';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Top10Panel = props => {
  const { Tag = 'div', variant, className, link, title, children } = props;
  return (
    <Tag className={`${styles.Top10Panel} ${styles[`Top10Panel__${variant}`]} ${className}`}>
      <div className="panel p-4 border rounded-2xl flex flex-col items-center justify-center">
        <h2 className="text-xl font-bold mb-2 text-center"> {title} </h2>
        <p className="mb-4 text-center"> {children} </p>
        <Button
          className="text-base rounded-lg py-3 !px-5 xs:w-38 sm:w-40"
          type="primary"
          as={Link} to={link}
          size="lg"
          onClick={() => window.scrollTo(0, 0)}
        >
          Read more
        </Button>
      </div>
    </Tag>
  );
};

Top10Panel.defaultProps = {
  variant: 'default',
  className: '',
};

Top10Panel.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Top10Panel;