import React from "react";
import ReactMarkdown from "react-markdown";
export default class Intro extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="py-3">
                <ReactMarkdown className="text-gray-500 text-base font-normal font-medium leading-6">
                    {this.props.children}
                </ReactMarkdown>
            </div>
        );
    }
}
