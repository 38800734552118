import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "X-Api-Key": process.env.REACT_APP_API_KEY,
  };
  return config;
});

export default httpClient;
