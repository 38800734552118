import React from "react";
import ReactMarkdown from "react-markdown";

export default class ListItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="py-2">
                <a
                    target="_blank"
                    href={this.props.provider.link}
                    className="underline text-gray-500 font-extrabold leading-6 py-1 nc-text-grad page-title"
                >
                    { }{' '}
                    <span className="underline-text ">{this.props.provider.domainTitle}</span>{' '}
                    {this.props.provider.title}
                </a>
                <ReactMarkdown className="text-gray-500 text-base font-normal font-medium leading-6 py-1">
                    {this.props.provider.description}
                </ReactMarkdown>
            </div>
        );
    }
}
