import PropTypes from "prop-types";
import styles from "./Domains.module.scss";
import SectionTitle from "components/SectionTitle";
import Accordion from "react-bootstrap/Accordion";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import DomainAccordion, {
  DomainAccordionLoader,
} from "components/DomainAccordion";
import { useCallback, useEffect, useMemo, useState } from "react";
import httpClient from "utils/http";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const Domains = (props) => {
  const { Tag = "div", variant, className } = props;
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const fetchDomains = useCallback(
    async (options) => {
      const _more = options?.more || false;
      setLoading(true);
      try {
        const res = await httpClient.get("/search", {
          params: {
            query: searchParams.get("keyword"),
            more: _more,
          },
        });
        if (res.data) {
          const updatedDomains = _more ? [...domains, ...res.data] : res.data;
          setDomains(updatedDomains);
        }
      } catch (e) {
        toast.error("Unable to retrieve domains");
      }
      setLoading(false);
    },
    [searchParams, domains]
  );

  // const onShowMoreBtnClick = () => {
  //   setShowMore(true);
  //   fetchDomains({ more: true });
  // };

  useEffect(() => {
    setShowMore(false);
    fetchDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Tag
      className={`${styles.Domains} ${
        styles[`Domains__${variant}`]
      } ${className}`}
    >
      <Container className="sm:px-0">
        <SectionTitle className="mb-2">Domains</SectionTitle>
        <Accordion>
          {!loading | showMore ? (
            <>
              {domains.map((domain, index) => (
                <DomainAccordion
                  key={domain.domain}
                  eventKey={`${index}`}
                  domain={domain}
                />
              ))}
            </>
          ) : (
            ""
          )}
          {loading && (
            <>
              {[...Array(5)].map((e, i) => (
                <DomainAccordionLoader key={i} />
              ))}
            </>
          )}
        </Accordion>
        {!showMore && domains.length ? (
          <div className="text-center">
            {/* <Button
              className="py-3 px-4 text-base border border-gray-300"
              variant="light"
              onClick={onShowMoreBtnClick}
            >
              See more
            </Button> */}
          </div>
        ) : (
          ""
        )}
      </Container>
    </Tag>
  );
};

Domains.defaultProps = {
  variant: "default",
  className: "",
};

Domains.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Domains;
