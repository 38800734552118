import { Container } from "react-bootstrap";
import Logo from "./Logo";

function Footer() {
  return (
    <div className="bg-nc-gray-100">
      <Container className="sm:px-0 py-16">
        <Logo />
        <p className="text-nc-gray-400 font-medium mb-10 leading-7">
          Search for available domain names and usernames all in one place.
        </p>
        <div className="text-nc-gray-400 font-medium leading-7">
          Copyright © 2023 12x.biz All Rights Reserved.
        </div>
        <p className="text-nc-gray-400 font-medium text-sm leading-7">
          Use of this Site is subject to express terms of use. By using this
          site, you signify that you agree to be bound by these{" "}
          <a
            className="underline"
            href="/terms-and-conditions"
          >
            Universal Terms of Service
          </a>
          .
        </p>
      </Container>
    </div>
  );
}

export default Footer;
