import PropTypes from "prop-types";
import styles from "./Badge.module.scss";
import BSBadge from "react-bootstrap/Badge";

const Badge = (props) => {
  const { variant, className, children } = props;
  return (
    <BSBadge
      className={`${styles.Badge} ${styles[`Badge__${variant}`]} ${className}`}
    >
      {children}
    </BSBadge>
  );
};

Badge.defaultProps = {
  variant: "default",
  className: "",
};

Badge.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Badge;
