import { Col, Row } from "react-bootstrap";
import DomainSearch from "./DomainSearch";
import UsernameSearch from "./UsernameSearch";

function Features() {
  return (
    <Row>
      <Col className="mb-4" lg={6}>
        <DomainSearch />
      </Col>
      <Col className="mb-4" lg={6}>
        <UsernameSearch />
      </Col>
    </Row>
  );
}

export default Features;
