import PropTypes from "prop-types";
import styles from "./FiverBanner.module.scss";
import { ReactComponent as FiverIcon } from "assets/icons/fiver.svg";
import { Button, Container } from "react-bootstrap";

const FiverBanner = (props) => {
  const { Tag = "div", variant, className } = props;
  return (
    <Tag
      className={`${styles.FiverBanner} ${
        styles[`FiverBanner__${variant}`]
      } ${className}`}
    >
      <Container className="sm:px-0">
        <div className="flex p-8 rounded-xl bg-gray-50 gap-8 items-center flex-col lg:flex-row">
          <div className="info">
            <div className="flex gap-3 lg:items-center">
              <div className="logo">
                <FiverIcon />
              </div>
              <div className="text">
                <h3 className="text-base font-semibold">
                  Bring Your Ideas to Life with Fiverr!
                </h3>
                <p className="text-gray-500">
                  Got a revolutionary idea? Need a talented developer, a
                  creative designer, a wordsmith, or a marketing maestro? Look
                  no further! Fiverr is your one-stop platform, bringing
                  together skilled professionals to transform your vision into a
                  reality.
                </p>
              </div>
            </div>
          </div>
          <div className="action">
            <Button
              type="primary"
              className="px-5 py-3 w-64 lg:w-60"
              href="https://www.fiverr.com"
              target="__blank"
              rel="noopener noreferrer"
            >
              Visit Fiverr
            </Button>
          </div>
        </div>
      </Container>
    </Tag>
  );
};

FiverBanner.defaultProps = {
  variant: "default",
  className: "",
};

FiverBanner.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default FiverBanner;
