import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout/DefaultLayout";
import Home from "../pages/Home/Home";
import Search from "../pages/Search/Search";
import About from "pages/About/About";
import Terms from "pages/Terms";
import Top10 from "pages/Top10";
import ConsistentOnlineIdentity from "pages/ConsistentOnlineIdentity";
import TheAdvantagesOfWebsiteCreators from "pages/TheAdvantagesOfWebsiteCreators";
import Top10ListOfWebsiteBuilders from "pages/Top10ListOfWebsiteBuilders";

export default createBrowserRouter([
  {
    path: "/",
    element: (
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    ),
  },
  {
    path: "/search",
    element: (
      <DefaultLayout>
        <Search />
      </DefaultLayout>
    ),
  },
  { // about
    path: "/about",
    element: (
      <DefaultLayout>
        <About />
      </DefaultLayout>
    ),
  },
  { // top-10-domain-providers
    path: "/top-10-domain-providers",
    element: (
      <DefaultLayout>
        <Top10 />
      </DefaultLayout>
    ),
  },
  {
    path: "/consistent-online-identity",
    element: (
      <DefaultLayout>
        <ConsistentOnlineIdentity />
      </DefaultLayout>
    ),
  },
  {
    path: "/the-advantages-of-website-creators",
    element: (
      <DefaultLayout>
        <TheAdvantagesOfWebsiteCreators />
      </DefaultLayout>
    ),
  },
  {
    path: "/top-10-list-of-website-builders",
    element: (
      <DefaultLayout>
        <Top10ListOfWebsiteBuilders />
      </DefaultLayout>
    ),
  },
  { //t&c
    path: "/terms-and-conditions",
    element: (
      <DefaultLayout>
        <Terms />
      </DefaultLayout>
    ),
  }
]);
