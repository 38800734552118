import PropTypes from 'prop-types';
import { Container } from "react-bootstrap";
import styles from './Terms.module.scss';

const Terms = () => {
  return (
    <div className={styles.Terms}>
      <Container className="sm:px-0 py-8">
        <div className="w-full sm:w-3/4">
          <h1 className="text-2xl font-extrabold leading-10 nc-text-grad page-title"> Terms of service for Namecheck.co </h1>
        </div>
  
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6 py-1">1. Acceptance of Terms:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> By accessing and using Namecheck.co ("the Website"), you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please refrain from using the Website. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">2. Use of the Website:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. The Website provides domain search and comparison services, including but not limited to username search functionality. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Users are solely responsible for the accuracy of the information provided during domain searches. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">3. User Accounts:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. Users may be required to create an account to access certain features of the Website. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Users are responsible for maintaining the confidentiality of their account information and are liable for any activities conducted through their account. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">4. Domain Search and Comparison:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. The Website may provide information about the availability and pricing of domain names from various registrars. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Namecheck.co does not guarantee the accuracy or availability of domain names listed on the Website. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">5. Username Search:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. The Username search feature allows users to search for usernames on various platforms. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Namecheck.co is not responsible for the accuracy or availability of usernames listed on the Website. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">6. User Conduct:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. Users agree not to use the Website for any unlawful or prohibited purposes. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Users are prohibited from engaging in any activity that may disrupt the functionality of the Website. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">7. Intellectual Property:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. All content on the Website, including text, graphics, logos, and images, is the property of Namecheck.co and is protected by intellectual property laws.</p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Users may not reproduce, distribute, or modify any content from the Website without the express written consent of Namecheck.co. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">8. Privacy Policy:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. The Website's privacy policy governs the collection and use of user information. By using the Website, you agree to the terms outlined in the privacy policy. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">9. Limitation of Liability:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. Namecheck.co is not liable for any damages or losses resulting from the use of the Website.</p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Namecheck.co is not responsible for the actions of third-party domain registrars or username platforms. </p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">10. Changes to Terms:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. Namecheck.co reserves the right to modify these terms and conditions at any time. Users are responsible for regularly reviewing the terms.</p>
        </div>
        <div className="py-2">
          <h4 className="text-gray-500 text-base font-bold leading-6">11. Affiliate Links:</h4>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> a. Namecheck.co may participate in affiliate marketing programs. This means that we may earn a commission when users click on our affiliate links and make a purchase or take other specified actions.</p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> b. Users acknowledge and agree that Namecheck.co may use affiliate links throughout the Website. These links help support the maintenance and development of our services. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> c. The use of affiliate links does not impact the accuracy or availability of domain names and usernames listed on the Website. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> d. Users understand that clicking on affiliate links may result in cookies being placed on their devices for tracking purposes, in accordance with our Privacy Policy. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> e. By using Namecheck.co, users consent to the use of affiliate links as described in these terms and conditions. </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 pt-4"> Contact information: </p>
          <p className="text-gray-500 text-base font-normal font-medium leading-6 py-1"> For questions or concerns regarding these terms and conditions, please contact Namecheck.co at  <a href="mailto:info@namecheck.co"> info@namecheck.co </a> </p>
        </div>
      </Container>
    </div>
  );
};

Terms.defaultProps = {
  className: '',
};

Terms.propTypes = {
  className: PropTypes.string,
};

export default Terms;