import PropTypes from "prop-types";
import styles from "./Logo.module.scss";
import BSNavbar from "react-bootstrap/Navbar";

const Logo = (props) => {
  const { Tag = "div", variant, className } = props;
  return (
    <Tag
      className={`${styles.Logo} ${styles[`Logo__${variant}`]} ${className}`}
    >
      <BSNavbar.Brand className="text-xl font-extrabold text-gray-900 p-0" href="/">
        Namecheck.co
      </BSNavbar.Brand>
    </Tag>
  );
};

Logo.defaultProps = {
  variant: "default",
  className: "",
};

Logo.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Logo;
