import React from "react";
export default class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="w-full">
                <h1 className="text-2xl font-extrabold leading-10 nc-text-grad page-title">
                    {' '}
                    {this.props.children}
                    {' '}
                </h1>
            </div>
        );
    }
}
