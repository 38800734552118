import PropTypes from "prop-types";
import styles from "./AvailabilityStatus.module.scss";

const text = {
  Available: "🎉 Available!",
  Unavailable: "🙅 Unavailable",
};

const AvailabilityStatus = (props) => {
  const { Tag = "span", variant, className, value } = props;
  return (
    <Tag
      className={`${styles.AvailabilityStatus} ${styles[`AvailabilityStatus__${variant}`]
        } ${className}`}
    >
      {value ? (
        <span className="text-green-500">{text.Available}</span>
      ) : (
        <span className="text-red-600">{text.Unavailable}</span>
      )}
    </Tag>
  );
};

AvailabilityStatus.defaultProps = {
  variant: "default",
  className: "",
};

AvailabilityStatus.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default AvailabilityStatus;
