import PropTypes from "prop-types";
import styles from "./SocialCard.module.scss";
import { ReactComponent as FacebookLogo } from "assets/icons/facebook.svg";
import { ReactComponent as InstagramLogo } from "assets/icons/instagram.svg";
import { ReactComponent as TiktokLogo } from "assets/icons/tiktok.svg";
import { ReactComponent as RedditLogo } from "assets/icons/reddit.svg";
import { ReactComponent as MediumLogo } from "assets/icons/medium.svg";
import { ReactComponent as PinterestLogo } from "assets/icons/pinterest.svg";
import { ReactComponent as SnapchatLogo } from "assets/icons/snapchat.svg";
import { ReactComponent as TumblrLogo } from "assets/icons/tumblr.svg";
import { ReactComponent as SoundcloudLogo } from "assets/icons/soundcloud.svg";
import { ReactComponent as WikipediaLogo } from "assets/icons/wikipedia.svg";
import { ReactComponent as YoutubeLogo } from "assets/icons/youtube.svg";
import { ReactComponent as TwitterLogo } from "assets/icons/x.svg";

import AvailabilityStatus from "components/AvailabilityStatus";

const logos = {
  facebook: FacebookLogo,
  instagram: InstagramLogo,
  tiktok: TiktokLogo,
  reddit: RedditLogo,
  medium: MediumLogo,
  pinterest: PinterestLogo,
  twitch: MediumLogo, //replace
  youtube: YoutubeLogo,
  twitter: TwitterLogo,
  github: MediumLogo, //replace
  snapchat: SnapchatLogo,
  tumblr: TumblrLogo,
  soundcloud: SoundcloudLogo,
  wikipedia: WikipediaLogo,
};

export const SocialCardLoader = (props) => {
  const { className } = props;
  return (
    <div className={`${styles.SocialCardLoader} ${className}`}>
      <div className="logo mx-auto my-3 shimmer" />
      <div className="status-label shimmer rounded-xl"></div>
    </div>
  );
};

const SocialCard = (props) => {
  const { Tag = "div", variant, className, social } = props;
  const Logo =
    logos[social.social] ??
    (() => <div className="text-center">{social.social}</div>);
  return (
    <Tag
      className={`${styles.SocialCard} ${styles[`SocialCard__${variant}`]
        } ${className} flex-none`}
    >
      <div className="text-center">
        <Logo className="mx-auto mt-3" />
        <span className="title text-base font-extrabold capitalize">{social.social}</span>
        <div>
          <AvailabilityStatus value={social.available} />
        </div>
      </div>
    </Tag>
  );
};

SocialCard.defaultProps = {
  variant: "default",
  className: "",
};

SocialCard.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default SocialCard;
