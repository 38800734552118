import PropTypes from "prop-types";
import styles from "./Search.module.scss";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Search = (props) => {
  const { Tag = "div", variant, className } = props;

  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    navigate(`/search?keyword=${keyword}`);
  };

  return (
    <Tag
      className={`${styles.Search} ${
        styles[`Search__${variant}`]
      } ${className}`}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="flex w-full sm:gap-3 xs:border border-nc-gray-300 rounded-lg sm:border-none">
          <Form.Control
            className="xs:text-sm sm:text-base rounded-lg px-4 py-3 text-gray-500 sm:w-96 md:w-full xs:border-none sm:border-solid border-nc-gray-300 shadow-none"
            size="lg"
            type="text"
            placeholder="Type your brand name here..."
            value={keyword}
            onChange={handleChange}
            required
          />
          <Button
            className="text-base rounded-lg py-2 !px-5 xs:w-38 sm:w-40"
            type="primary"
            size="lg"
          >
            Search
          </Button>
        </div>
      </Form>
    </Tag>
  );
};

Search.defaultProps = {
  variant: "default",
  className: "",
};

Search.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Search;
