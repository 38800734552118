import styles from "./HeadCaption.module.scss";

function HeadCaption() {
  return (
    <div className={styles.HeadCaption}>
      <h1 className="text-center xs:text-5xl font-extrabold nc-text-grad xs:mb-4 md:mb-2">
        Streamlined Search for Domains and Usernames
      </h1>
      <h3 className="font-medium text-black text-center mx-auto">
        Effortlessly find and secure your perfect online identity in one
        convenient hub.
      </h3>
    </div>
  );
}

export default HeadCaption;
