import PropTypes from "prop-types";
import styles from "./DomainSearch.module.scss";
import AvailabilityStatus from "components/AvailabilityStatus";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";

const DomainSearch = (props) => {
  const { Tag = "div", variant, className } = props;
  return (
    <Tag
      className={`${styles.DomainSearch} ${
        styles[`DomainSearch__${variant}`]
      } ${className} bg-gradient-to-r from-indigo-500 to-violet-400 rounded-2xl`}
    >
      <div className="xs:px-8 xs:py-9 sm:px-10 sm:py-11">
        <h3 className="text-white text-xl font-extrabold text-center mb-2">
          Domain name search
        </h3>
        <p className="description text-white mx-auto !mb-5 text-center xs:text-sm sm:text-base">
          With just one search, you’ll be searching the availability of hundreds
          of different domains.
        </p>
        <ul className="domain-mock-list">
          <li className="mb-4">
            <div className="mock-domain rounded-lg xs:!px-6 sm:!px-8 xs:!py-4 sm:!py-5 bg-slate-100">
              <div className="flex justify-between">
                <div className="col lft flex flex-auto items-center">
                  <span className="title font-extrabold">
                    exampleexample.com
                  </span>
                  <AvailabilityStatus className="status ml-1" value={true} />
                </div>
                <div className="col rgt w-24 flex-auto">
                  <div className="btn-buy-now flex items-center justify-end">
                    <span className="underline font-semibold">Buy now</span>
                    <span className="ml-1">
                      <ChevronDown />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="mb-4">
            <div className="mock-domain rounded-lg xs:!px-6 sm:!px-8 xs:!py-4 sm:!py-5 bg-slate-100">
              <div className="flex justify-between">
                <div className="col lft flex flex-auto items-center">
                  <span className="title font-extrabold">
                    exampleexample.net
                  </span>
                  <AvailabilityStatus className="status ml-1" value={true} />
                </div>
                <div className="col rgt w-24 flex-auto">
                  <div className="btn-buy-now flex items-center justify-end">
                    <span className="underline font-semibold">Buy now</span>
                    <span className="ml-1">
                      <ChevronDown />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="">
            <div className="mock-domain rounded-lg xs:!px-6 sm:!px-8 xs:!py-4 sm:!py-5 bg-slate-100">
              <div className="flex justify-between">
                <div className="col lft flex flex-auto items-center">
                  <span className="title font-extrabold">
                    exampleexample.co
                  </span>
                  <AvailabilityStatus className="ml-1" value={false} />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Tag>
  );
};

DomainSearch.defaultProps = {
  variant: "default",
  className: "",
};

DomainSearch.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default DomainSearch;
