import PropTypes from "prop-types";
import styles from "./Social.module.scss";
import SectionTitle from "components/SectionTitle";
import SocialCard, { SocialCardLoader } from "components/SocialCard";
import { Container } from "react-bootstrap";
import { useCallback, useEffect, useMemo, useState } from "react";
import httpClient from "utils/http";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const Social = (props) => {
  const { Tag = "div", variant, className } = props;

  const [loading, setLoading] = useState(false);
  const [socials, setSocials] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const fetchSocials = useCallback(async () => {
    setLoading(true);
    try {
      const res = await httpClient.get("/social", {
        params: {
          query: searchParams.get("keyword"),
        },
      });
      if (res.data) {
        setSocials(res.data);
      }
    } catch (e) {
      toast.error("Unable to retrieve usernames");
    }
    setLoading(false);
  }, [searchParams]);

  useEffect(() => {
    fetchSocials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Tag
      className={`${styles.Social} ${styles[`Social__${variant}`]
        } ${className}`}
    >
      <Container className="sm:px-0">
        <SectionTitle className="mb-2">Usernames</SectionTitle>
      </Container>
      <Container fluid="sm" className="px-0">
        <div className="slider">
          {loading ? (
            <>
              {[...Array(4)].map((e, i) => (
                <SocialCardLoader className="slide" key={i} />
              ))}
            </>
          ) : (
            <>
              {socials?.map((social) => (
                <SocialCard
                  key={social.social}
                  social={social}
                  className="slide"
                />
              ))}
            </>
          )}
        </div>
      </Container>
    </Tag>
  );
};

Social.defaultProps = {
  variant: "default",
  className: "",
};

Social.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default Social;
