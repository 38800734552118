import PropTypes from "prop-types";
import styles from "./Search.module.scss";
import SearchBar from "components/SearchBar";
import Social from "components/search/Social";
import Domains from "components/search/Domains";
import FiverBanner from "components/FiverBanner";
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Search = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const keyword = searchParams.get("keyword");
    if (!keyword) {
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <div className={`${styles.Search}`}>
      <section>
        <SearchBar />
      </section>
      <section className="mb-8">
        <Social />
      </section>
      <section className="mb-3">
        <Domains />
      </section>
      <section className="mb-20 lg:mb-40">
        <FiverBanner />
      </section>
    </div>
  );
};

Search.defaultProps = {
  className: "",
};

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;