import BSNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Logo from "./Logo";


function Navbar() {
  return (
    <BSNavbar className="p-0 border-b border-gray-200">
      <Container className="sm:px-0 py-7">
        <Logo />
        <Nav className="ms-auto">
          <Nav.Link
            className="text-base font-semibold text-gray-500"
            href="/about"
          >
            About
          </Nav.Link>
          <Nav.Link
            className="text-base font-semibold text-gray-500 !pr-0 inline md:hidden"
            href="/top-10-domain-providers"
          >
            Top 10
          </Nav.Link>
          <Nav.Link
            className="text-base font-semibold text-gray-500 !pr-0 hidden md:inline"
            href="/top-10-domain-providers"
          >
            Top 10 Domain Providers
          </Nav.Link>
        </Nav>
      </Container>
    </BSNavbar>
  );
}

export default Navbar;
