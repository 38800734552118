import PropTypes from "prop-types";
import styles from "./SectionTitle.module.scss";

const SectionTitle = (props) => {
  const { Tag = "h3", variant, className, children } = props;
  return (
    <Tag
      className={`${styles.SectionTitle} ${
        styles[`SectionTitle__${variant}`]
      } ${className} text-xl font-extrabold`}
    >
      <span className="nc-text-grad">{children}</span>
    </Tag>
  );
};

SectionTitle.defaultProps = {
  variant: "default",
  className: "",
};

SectionTitle.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default SectionTitle;
