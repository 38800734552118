import PropTypes from "prop-types";
import styles from "./UsernameSearch.module.scss";
import AvailabilityStatus from "components/AvailabilityStatus";
import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as Twitter } from "assets/icons/x.svg";
import { ReactComponent as TikTok } from "assets/icons/tiktok.svg";

const UsernameSearch = (props) => {
  const { Tag = "div", variant, className } = props;
  return (
    <Tag
      className={`${styles.UsernameSearch} ${styles[`UsernameSearch__${variant}`]
        } ${className} bg-gradient-to-r from-indigo-500 to-violet-400 rounded-2xl`}
    >
      <div>
        <div className="px-10 py-11">
          <h3 className="text-white text-xl font-extrabold text-center mb-2">
            Username search
          </h3>
          <p className="description text-white mx-auto !mb-5 text-center xs:text-sm sm:text-base">
            At the same time, we’ll check to see if the username is available
            too on platforms such as Instagram, Snapchat, Tumblr, and
            Soundcloud.
          </p>
        </div>
        <div className="overflow-hidden mb-4">
          <div className="flex items-center !gap-5 !-ml-5 !-mr-5 justify-between">
            <div className="soc-card tumbler rounded-lg flex flex-col bg-slate-100">
              <div className="logo mb-2">
                <Twitter />
              </div>
              <span className="title font-extrabold">X</span>
              <AvailabilityStatus className="status" value={false} />
            </div>
            <div className="soc-card instagram rounded-lg flex flex-col bg-slate-100">
              <div className="logo mb-2">
                <Instagram />
              </div>
              <span className="title font-extrabold">Instagram</span>
              <AvailabilityStatus className="status" value={true} />
            </div>
            <div className="soc-card snapchat rounded-lg flex flex-col bg-slate-100">
              <div className="logo mb-2">
                <TikTok />
              </div>
              <span className="title font-extrabold">TikTok</span>
              <AvailabilityStatus className="status" value={true} />
            </div>
          </div>
        </div>
      </div>
    </Tag>
  );
};

UsernameSearch.defaultProps = {
  variant: "default",
  className: "",
};

UsernameSearch.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default UsernameSearch;
